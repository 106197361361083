<template>
  <!-- eslint-disable vue/no-v-html -->
  <!-- nosemgrep: javascript.vue.security.audit.xss.templates.avoid-v-html.avoid-v-html -->
  <div v-html="html" />
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import { type RichText } from '@backmarket/http-api/src/api-specs-content/models/rich-text'
import { isEmpty } from '@backmarket/utils/object/isEmpty'
import { documentToHtmlString } from '@contentful/rich-text-html-renderer'

const props = defineProps<{ richText?: RichText }>()

const html = computed(() =>
  !isEmpty(props?.richText) ? documentToHtmlString(props.richText) : '',
)
</script>
