import { useRoute } from '#app'
import { type MaybeRefOrGetter, toValue } from 'vue'

import { type Quiz } from '@backmarket/http-api/src/api-specs-content/models/quiz'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'

export function useQuizTracking(quizRef: MaybeRefOrGetter<Quiz>) {
  const { trackClick } = useTracking()

  function trackAnswerClick({
    questionIndex,
    answerIndex,
  }: {
    questionIndex: number
    answerIndex: number
  }) {
    const quiz = toValue(quizRef)
    const { fullPath, name: routeName } = useRoute()

    trackClick({
      value: `question_${questionIndex + 1}`,
      position: `answer_${answerIndex + 1}`,
      pageType: routeName as string,
      name: quiz.technicalName,
      fullPath,
    })
  }

  return { trackAnswerClick }
}
